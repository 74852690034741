import React from 'react';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import RGL, { WidthProvider } from 'react-grid-layout';
import { DetailsCardSkeleton } from 'pages/WidgetManagement/common/Skeletons';
import { GRID_COLS, GRID_ROWS } from '@fiji/common/src/constants';
import {
    removeWidget,
    setDashboardWidgetIds,
    setLayout,
} from '@fiji/common/src/features/dashboardManagement/dashboardSlice';
import { useGetDashboardByIdQuery } from '@fiji/common/src/features/dashboardManagement/dashboardApi';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ConfirmModal, CustomTransComponent, DynamicWidget, Loader } from '../../../components';
import DeleteForever from '@mui/icons-material/DeleteForever';
import Edit from '@mui/icons-material/Edit';
import MoreVert from '@mui/icons-material/MoreVert';
import OpenWith from '@mui/icons-material/OpenWith';
import { Avatar, Box, Card, CardContent, CardHeader, Divider, Stack, useTheme } from '@mui/material';
import { CustomMenu } from 'components/CustomMenu';
import { useConfirm } from '@fiji/common/src/hooks';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { DriveFileRenameOutline, ErrorOutline, PushPin } from '@mui/icons-material';
import { CustomEmptyState } from 'components/CustomEmptyState';
import { RenameModal } from 'pages/WidgetManagement/WidgetList/RenameModal';
import { getWidgetTypeValidationConfig, isWidgetApplicable, isWidgetConfigured } from '@fiji/common/src/utils/helpers';
import { useRBAC } from 'hooks';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { WidgetHeader } from 'pages/WidgetManagement/common';

const ResponsiveReactGridLayout = WidthProvider(RGL);

type Props = {
    dashboardMode: string;
    isLoading?: boolean;
};
export const GridLayout = ({ isLoading, dashboardMode }: Props): JSX.Element => {
    const navigate = useNavigate();
    const { dashboardId } = useParams();
    const { state } = useLocation();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const currentOrg = useTypedSelector(selectedOrg);
    const currentRealmName = useTypedSelector((selectorState) => selectorState.common.selectedRealm);
    const configurableDashboard = useTypedSelector((selectorState) => selectorState[dashboardMode]);
    const loadingState = useTypedSelector((rootState) => rootState[dashboardMode].loaders);
    const renameWidgetModalRef = React.useRef<any>(null);

    const permissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permissions);

    const canUpdateUserWidget = hasPermission('edit-user-widgets');
    const canUpdateSystemWidget = hasPermission('edit-system-widgets');

    const { currentData: dashboard }: any = useGetDashboardByIdQuery(dashboardId, {
        skip: !dashboardId || !currentOrg.id,
    });
    const handleRemoveWidget = (): void => {
        dispatch(removeWidget(widgetData.id));
        onCancel();
        dispatch(
            setMessageContent({
                isOpen: true,
                message: `${widgetData.name} has been removed.`,
            })
        );
    };
    const { data: widgetData, isVisible, onClick, onCancel, onConfirm } = useConfirm(handleRemoveWidget);

    const renderLoader = (type: any): JSX.Element => {
        if (getWidgetTypeValidationConfig(type)['loaderType'] === 'skeleton') {
            return (
                <DetailsCardSkeleton
                    secondarylength={5}
                    {...(getWidgetTypeValidationConfig(type)?.['primarySkeletonCount'] && {
                        primarylength: getWidgetTypeValidationConfig(type)?.['primarySkeletonCount'],
                    })}
                />
            );
        }
        return <Loader size={55} />;
    };

    const getMenuGroups = (data: any): any => {
        const menuGroups: any = [];
        if ((data?.isSystemWidget && canUpdateSystemWidget) || (!data?.isSystemWidget && canUpdateUserWidget)) {
            menuGroups.push(
                {
                    title: 'Edit',
                    icon: <Edit />,
                    onClick: (): void => {
                        navigate(`/${currentRealmName}/editWidget/${data?.id}`, {
                            state: {
                                typeId: data?.widgetType?.id,
                                mode: 'dashboard',
                                dashboardId: dashboardId,
                                assignType: state.assignType,
                                assignId: state.assignId,
                                configurationType: 'edit',
                            },
                        });
                        dispatch(setDashboardWidgetIds(data?.id));
                        dispatch(removeWidget(data.id));
                    },
                },
                {
                    title: 'Rename',
                    icon: <DriveFileRenameOutline />,
                    onClick: (): void => {
                        renameWidgetModalRef?.current?.handleModalAction(true, data);
                    },
                }
            );
        }

        menuGroups.push({
            title: 'Remove',
            icon: <DeleteForever />,
            onClick: (): any => {
                onClick(data);
            },
        });

        return menuGroups;
    };

    const getActionsBtn = (data: any): any => ({
        id: 'profile-menu',
        menuGroups: getMenuGroups(data),
        menuTitle: '',
        menuSubtitle: '',
        avatar: (open?: any): JSX.Element => (
            <Avatar className="bg-light-blue-avatar">
                <MoreVert className={`${open ? theme?.palette?.primary?.main : 'color-content'}`} />
            </Avatar>
        ),
    });

    const getCardHeaderTitle = (data?: any): any => (
        <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            className="padding-0"
            sx={{ height: '48px' }}
        >
            <Stack direction={'row'} alignItems={'center'}>
                <Box className="min-width-48 min-height-48 bg-primary-50 border-radius-top-left flex-center-align">
                    <OpenWith color="primary" className="drag-handle cursor-pointer" />
                </Box>
            </Stack>
            <Stack direction={'row'} sx={{ zIndex: 99 }}>
                <Box className="min-width-48 custom-position min-height-48 bg-primary-50 border-radius-top-right flex-center-align margin-left-16">
                    <CustomMenu menuList={getActionsBtn(data)} />
                </Box>
            </Stack>
        </Stack>
    );

    return (
        <>
            {isLoading && (
                <div className="position-relative custom-loader-height">
                    <Loader size={60} />
                </div>
            )}

            {configurableDashboard?.widgetList
                ?.filter((item: any) => item?.config?.isPinned)
                ?.map((widget: any) => (
                    <Box key={widget?.id} sx={{ position: 'relative' }}>
                        <Box
                            sx={{
                                position: 'absolute',
                                zIndex: 999,
                                width: '100%',
                                height: '48px',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    color: theme?.palette?.primary?.main,
                                    padding: '16px',
                                }}
                            >
                                {widget.name}
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Box
                                    sx={{
                                        height: '48px',
                                        width: '48px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <PushPin
                                        sx={{ color: theme?.palette?.primary?.main, height: '32px', width: '32px' }}
                                    />
                                </Box>
                                <Box className="min-width-48 custom-position min-height-48 bg-primary-50 border-radius-top-right flex-center-align">
                                    <CustomMenu menuList={getActionsBtn(widget)} />
                                </Box>
                            </Box>
                        </Box>

                        {!loadingState[widget?.id] && (
                            <CardContent className="padding-0 custom-card-height">
                                {renderLoader(widget?.widgetType?.id)}
                            </CardContent>
                        )}
                        {loadingState[widget?.id] && (
                            <DynamicWidget
                                mode="edit-pinned"
                                widgetTypeId={widget?.widgetType?.id}
                                {...(widget?.widgetType?.id === 'map'
                                    ? {
                                          countKey: 'deviceCount',
                                          widgetId: widget?.id,
                                          clustering: false,
                                          widgetData: { ...widget?.config, widgetName: widget?.name },
                                      }
                                    : {
                                          widgetData: {
                                              internalCdnJsLink: widget?.widgetType?.internalCdnJsLink,
                                              ...(widget?.config
                                                  ? { ...widget?.config, widgetName: widget?.name }
                                                  : {
                                                        primary: [],
                                                        secondary: [],
                                                        secondaryChannelCount: 6,
                                                    }),
                                          },
                                      })}
                            />
                        )}
                    </Box>
                ))}

            <ResponsiveReactGridLayout
                className="layout w-100"
                compactType="vertical"
                autoSize={true}
                containerPadding={[10, 50]}
                draggableHandle=".drag-handle"
                layout={
                    configurableDashboard?.widgetList?.length >= dashboard?.data?.widgetIdList.length &&
                    configurableDashboard?.layout
                }
                onLayoutChange={(res): void => {
                    if (configurableDashboard?.widgetList?.length === configurableDashboard?.layout?.length) {
                        dispatch(setLayout(res));
                    }
                }}
                style={{ position: 'absolute' }}
                cols={GRID_COLS}
                rowHeight={GRID_ROWS}
            >
                {Boolean(configurableDashboard?.widgetList?.length) &&
                    configurableDashboard?.widgetList?.map(
                        (widget: any) =>
                            !widget?.config?.isPinned && (
                                <div key={widget?.id} style={{ zIndex: 999 }}>
                                    <Card className="margin-left-0" sx={{ height: '100%' }}>
                                        <CardHeader
                                            className="border-bottom-1 padding-0"
                                            title={getCardHeaderTitle(widget)}
                                        />
                                        {!loadingState[widget?.id] && (
                                            <CardContent className="padding-0 custom-card-height">
                                                {renderLoader(widget?.widgetType?.id)}
                                            </CardContent>
                                        )}
                                        {loadingState[widget?.id] &&
                                            isWidgetApplicable(
                                                widget,
                                                state['assignType'] === 'GROUP' ? 'GROUP' : 'DEVICE'
                                            ) &&
                                            isWidgetConfigured(widget) && (
                                                <DynamicWidget
                                                    mode="edit"
                                                    widgetTypeId={widget?.widgetType?.id}
                                                    {...(widget?.widgetType?.id === 'map'
                                                        ? {
                                                              countKey: 'deviceCount',
                                                              widgetId: widget?.id,
                                                              clustering: false,
                                                              widgetData: {
                                                                  ...widget?.config,
                                                                  widgetName: widget?.name,
                                                              },
                                                          }
                                                        : {
                                                              widgetData: {
                                                                  internalCdnJsLink:
                                                                      widget?.widgetType?.internalCdnJsLink,
                                                                  ...(widget?.config
                                                                      ? { ...widget?.config, widgetName: widget?.name }
                                                                      : {
                                                                            primary: [],
                                                                            secondary: [],
                                                                            secondaryChannelCount: 6,
                                                                        }),
                                                              },
                                                          })}
                                                />
                                            )}

                                        {loadingState[widget.id] &&
                                            !isWidgetApplicable(
                                                widget,
                                                state['assignType'] === 'GROUP' ? 'GROUP' : 'DEVICE'
                                            ) && (
                                                <Card className="margin-left-0" sx={{ height: '100%' }}>
                                                    <WidgetHeader
                                                        mode="edit"
                                                        widgetData={{ widgetName: widget?.name }}
                                                    />
                                                    <Divider />
                                                    <CardContent>
                                                        <CustomEmptyState
                                                            icon={<ErrorOutline className="height-100 width-100" />}
                                                            title={`${widget?.name} Not Applicable`}
                                                            className="padding-y-80"
                                                            description="This widget type does not support selected group or device."
                                                        />
                                                    </CardContent>
                                                </Card>
                                            )}

                                        {loadingState[widget.id] &&
                                            isWidgetApplicable(
                                                widget,
                                                state['assignType'] === 'GROUP' ? 'GROUP' : 'DEVICE'
                                            ) &&
                                            !isWidgetConfigured(widget) && (
                                                <Card className="margin-left-0" sx={{ height: '100%' }}>
                                                    <WidgetHeader
                                                        mode="edit"
                                                        widgetData={{ widgetName: widget?.name }}
                                                    />
                                                    <Divider />
                                                    <CardContent>
                                                        <CustomEmptyState
                                                            icon={<ErrorOutline className="height-100 width-100" />}
                                                            title={`${widget?.name} Not Configured`}
                                                            className="padding-y-80"
                                                            description="This widget type is not configured."
                                                        />
                                                    </CardContent>
                                                </Card>
                                            )}
                                    </Card>
                                </div>
                            )
                    )}
            </ResponsiveReactGridLayout>
            <ConfirmModal
                onCancel={onCancel}
                confirmClick={onConfirm}
                actionButtonText={<CustomTransComponent translationKey={'COMMON:REMOVE'} />}
                header={
                    <CustomTransComponent
                        translationKey={'WIDGETS:REMOVE_WIDGET_MODAL.HEADER'}
                        replace={{
                            widgetName: widgetData?.name,
                        }}
                    />
                }
                description={
                    <CustomTransComponent
                        translationKey={'WIDGETS:REMOVE_WIDGET_MODAL.DESCRIPTION'}
                        replace={{
                            widgetName: widgetData?.name,
                        }}
                    />
                }
                isVisible={isVisible}
            />

            <RenameModal key="da4fqfcq42fg3" ref={renameWidgetModalRef} />
        </>
    );
};
