import React from 'react';
import { CustomModal, CustomTransComponent, Hierarchy, renderGroupHierarchyIcons } from '../../../components';
import { IconButton, Stack } from '@mui/material';
import Search from '@mui/icons-material/Search';
import { ModalActions } from '../../../components/CustomModal';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { RealmHierarchySkeleton } from '../../RealmManagement/Skeletons/RealmHierarchySkeleton';
import {
    setSelectedPreviewNode,
    setWidgetDevice,
    togglePreviewNodeModal,
} from '@fiji/common/src/features/widgetManagement/widgetConfigurationSlice';
import { useChannelHierarchyHandlers, useWidgetConfigHandlers } from '../common';
import { InputWithCustomPlaceholder } from 'components/InputWithCustomPlaceholder';
import { useSearchDeviceOrGroup } from '@fiji/common/src/hooks';
import { getWidgetTypeValidationConfig } from '@fiji/common/src/utils/helpers';

export const PreviewDeviceModal = (): JSX.Element => {
    const selectedDeviceNode = useTypedSelector((state) => state.widgetConfiguration.device);
    const selectedPreviewNode = useTypedSelector((state) => state.widgetConfiguration?.node);
    const selectedSource = useTypedSelector((state) => state.widgetConfiguration.source);
    const isModalVisible = useTypedSelector((state) => state.widgetConfiguration.previewNodeModal['isVisible']);
    const selectedOrg = useTypedSelector((state) => state.org.selectedOrg);
    const dispatch = useAppDispatch();
    const [searchKey, setSearchKey] = React.useState(null);

    const {
        details: { widgetDetails: details },
    } = useWidgetConfigHandlers();

    const [controlledExpandedNodes, setControlledExpandedNodes] = React.useState<string[]>([]);
    const { currentData: filteredRecords, isLoading } = useSearchDeviceOrGroup({ searchKey });

    const filters: any = [
        {
            key: 'type',
            operator: '!==',
            value:
                details?.widgetType?.id === 'group_list' ||
                details?.widgetType?.id === 'map' ||
                details?.widgetType?.id === 'device_list' ||
                details?.widgetType?.id === 'score' ||
                details?.widgetType?.id === 'components'
                    ? 'DEVICE'
                    : 'GROUP',
            action: 'disableCheck',
        },
    ];
    if (selectedSource?.id) {
        filters.push({
            key: 'deviceTypeId',
            operator: '===',
            value: selectedSource?.id,
            action: 'disableCheck',
        });
    }

    const {
        groupsData,
        handleCleanHierarchyNode,
        handleFetchHierarchy,
        hierarchyMappingData,
        hasChildNode,
        loadingHierarchyNode,
    } = useChannelHierarchyHandlers({ widgetType: details?.widgetType?.id });

    const actions: ModalActions[] = [
        {
            key: '#cancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            variant: 'outlined',
            close: true,
            handleClick: (): void => {
                dispatch(setSelectedPreviewNode(undefined));
                dispatch(togglePreviewNodeModal(false));
            },
        },
        {
            key: '#save',
            label: <CustomTransComponent translationKey={'COMMON:SAVE_LABEL'} />,
            variant: 'contained',
            handleClick: (): void => {
                dispatch(setWidgetDevice({ data: selectedPreviewNode, reset: true }));
                dispatch(setSelectedPreviewNode(undefined));
                dispatch(togglePreviewNodeModal(false));
            },
        },
    ];

    const handleNodeClick = (device: any): void => {
        dispatch(setSelectedPreviewNode(device));
    };

    return (
        <CustomModal
            isOpen={isModalVisible}
            isLoading={false}
            type="primary"
            actions={actions}
            header={
                <CustomTransComponent
                    translationKey={'WIDGETS:PREVIEW_MODAL.HEADER'}
                    replace={{
                        replace: {
                            header: getWidgetTypeValidationConfig(details?.widgetType?.id)['isSourceRequired']
                                ? 'Device'
                                : 'Node',
                        },
                    }}
                />
            }
        >
            <Stack my={2} direction={'row'} className="border-1 border-radius-4">
                <IconButton type="button" className="padding-10" aria-label="search">
                    <Search />
                </IconButton>
                <InputWithCustomPlaceholder
                    placeholder={'COMMON:SEARCH_PLACEHOLDER'}
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchKey ?? ''}
                    onChange={(e: any) => {
                        setSearchKey(e.target.value);
                    }}
                />
            </Stack>
            <Stack className="height-432 overflow-y-scroll">
                <Hierarchy
                    key="#12e1de2r42f"
                    labelKey="name"
                    {...(filteredRecords &&
                        Boolean(filteredRecords.length) && {
                            data: filteredRecords,
                        })}
                    {...(!filteredRecords && {
                        data: selectedOrg?.defaultGroupAccess
                            ? [
                                  {
                                      id: selectedOrg?.id,
                                      name: `${selectedOrg?.name} (Organization Root)`,
                                      icon: { web: { family: 'material-ui', name: 'CorporateFare' } },
                                      groupCount: selectedOrg?.groupCount,
                                      deviceCount: selectedOrg?.deviceCount,
                                      type: 'GROUP',
                                  },
                              ]
                            : groupsData,
                    })}
                    loadMore={handleFetchHierarchy}
                    hierarchyMappingData={hierarchyMappingData}
                    cleanHiearchyNode={handleCleanHierarchyNode}
                    loadingNode={loadingHierarchyNode}
                    controlledExpandedNodes={controlledExpandedNodes}
                    setControlledExpandedNodes={setControlledExpandedNodes}
                    selectionType="radio"
                    isLoading={!groupsData || isLoading}
                    handleTreeNodeSelection={handleNodeClick}
                    loadingSkeleton={<RealmHierarchySkeleton row={3} />}
                    hasChild={hasChildNode}
                    selectedNodes={selectedPreviewNode?.id ?? selectedDeviceNode?.id}
                    getAvatarChild={renderGroupHierarchyIcons}
                    filters={
                        details?.widgetType?.id !== 'timeline' && details?.widgetType?.id !== 'schedule' && filters
                    }
                />
            </Stack>
        </CustomModal>
    );
};
