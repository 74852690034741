import React, { useEffect, useRef } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { WidgetHeader, WidgetFooter, CHART_INITIAL_CONFIG } from 'pages/WidgetManagement/common';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { Box, Button, Divider, MenuItem, Paper, Radio, Stack } from '@mui/material';
import { StyledMenu } from 'pages/Chart/StyledMenu';
import { unitList } from 'pages/Chart/Chart';
import { CustomTransComponent } from 'components';
import { getStringGroupPath } from 'pages/WidgetManagement/common/utils/helpers';
import { useTransformData } from 'hooks/useTransformData';

type TelemetryChartProps = {
    type?: 'bar' | 'column';
    widgetTypeId?: string;
    route?: string;
    state?: string;
    maxCount?: number;
    secondary: any[];
    widgetName?: string;
    [key: string]: any;
};

const Component = ({
    widgetData: chartWidget,
    mode,
}: {
    widgetData: TelemetryChartProps;
    mode: string;
    unitChangeHandler: any;
}): JSX.Element => {
    const { pointConversion } = useTransformData();
    const chartRef: any = useRef(null);
    const [option, setOption] = React.useState(CHART_INITIAL_CONFIG);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedUnits, setSelectedUnits] = React.useState<any>(chartWidget?.selectedUnit ?? '');
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };

    useEffect(() => {
        Highcharts.setOptions({
            yAxis: {
                lineColor: chartWidget?.type === 'bar' ? '#f0ebeb' : '#ffffff',
            },
        });
    }, [chartWidget?.type]);

    const getSeriesData = (): any[] => {
        let data = JSON?.parse(
            JSON?.stringify(
                chartWidget?.secondary?.slice(0, chartWidget?.maxCount ?? chartWidget?.secondary?.length) ?? []
            )
        );
        if (selectedUnits) {
            data = data?.filter((item: any) => item?.unit === selectedUnits);
        }
        return data.map((item: any) => ({
            name: chartWidget?.multiDevice
                ? `${item?.displayName}/${getStringGroupPath(item?.devicePath, item?.deviceName)}`
                : item?.displayName ?? '',
            data: [item?.value ?? 0],
            tooltip: {
                valueSuffix: item?.unit ? ` ${item?.unit}` : '%',
            },
        }));
    };

    const getIntervalValue = (dataValue: any): any => {
        const value: number = dataValue?.reduce?.(
            (max: any, current: any) => (current.value > max.value ? current : max),
            dataValue?.[0]
        )?.value;
        if (value > 10) return Math.ceil(value / 10);
        return value ? (value / 12)?.toFixed?.(2) : undefined;
    };

    useEffect(() => {
        const chart = chartRef?.current?.chart;
        if (chart) {
            const cloneChart: any = JSON.parse(JSON.stringify(option));
            cloneChart['series'] = getSeriesData() ?? [];
            cloneChart['chart'] = {
                type: chartWidget?.type,
            };

            const data: any = JSON?.parse(JSON.stringify(chartWidget?.secondary ?? []));
            cloneChart['plotOptions'] = {
                bar: {
                    dataLabels: {
                        enabled: true,
                        y: 25,
                        alignTo: 'plotEdges',
                        align: 'left',
                    },
                    borderRadius: '20%',
                    pointWidth: 30,
                },
                column: {
                    dataLabels: {
                        x: 25,
                        align: 'right',
                        alignTo: 'plotEdges',
                        enabled: true,
                        rotation: 270,
                    },
                    borderRadius: '20%',
                    pointWidth: 30,
                },
                series: {
                    groupPadding: 0,
                    pointPadding: 0.3,
                    dataLabels: {
                        enabled: true,
                        // align: 'left',
                        formatter: function (this: any): string {
                            return `${pointConversion(this?.y)} ${this?.series?.tooltipOptions?.valueSuffix} ${
                                this?.series?.name
                            }`;
                        },
                        style: {
                            fontSize: '14px',
                        },
                    },
                },
            };
            cloneChart['yAxis'] = {
                ...(option?.yAxis ?? {}),
                labels: {
                    format: `{value} ${
                        selectedUnits
                            ? data
                                  ?.filter((item: any) => item?.unit === selectedUnits)
                                  ?.reduce?.(
                                      (max: any, current: any) => (current.value > max.value ? current : max),
                                      data[0]
                                  )?.unit ?? ''
                            : data?.reduce?.(
                                  (max: any, current: any) => (current.value > max.value ? current : max),
                                  data[0]
                              )?.unit ?? ''
                    }`,
                    overflow: 'justify',
                },
                tickInterval: getIntervalValue(data),
            };
            setOption(cloneChart);
        }
    }, [chartWidget, selectedUnits]);

    return (
        <Paper sx={{ height: '100%' }}>
            <WidgetHeader mode={mode} widgetData={chartWidget} />
            {mode !== 'edit' && <Divider />}
            <Stack
                sx={{
                    height: `calc(100% - ${mode === 'edit' || chartWidget?.viewAllButton ? '97px' : '49px'})`,
                    overflow: 'auto',
                }}
            >
                <Stack
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    alignItems={'flex-end'}
                    p={2}
                    pr={5}
                >
                    <Box mr={2}>
                        <Button
                            disabled={mode === 'preview' || mode === 'edit'}
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            sx={{
                                backgroundColor: 'transparent',
                                color: '#424E54',
                            }}
                            onClick={handleClick}
                            endIcon={!open ? <ArrowDropDown /> : <ArrowDropUp />}
                        >
                            <CustomTransComponent translationKey={'COMMON:UNITS'} />
                        </Button>
                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            {unitList?.map((item: any): any => (
                                <MenuItem key={item?.value} value={item?.value} className="w-100">
                                    <Radio
                                        className="margin-right-16"
                                        checked={selectedUnits === item.value}
                                        onChange={(): void => setSelectedUnits(item.value)}
                                    />
                                    {`(${item?.value}) ${item.name}`}
                                </MenuItem>
                            ))}
                        </StyledMenu>
                    </Box>
                </Stack>
                <HighchartsReact highcharts={Highcharts} options={option} ref={chartRef} />
            </Stack>

            <WidgetFooter
                mode={mode}
                widgetData={{ ...chartWidget, viewAllButton: true }}
                ids={[]}
                disabled={!chartWidget?.secondary?.length}
                actionBtnName={<CustomTransComponent translationKey={'COMMON:VIEW_FULL_CHART'} />}
            />
        </Paper>
    );
};
export default Component;
