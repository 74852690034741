import { RootState } from '../../app/store';
import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { apiResponseHelper, setDashboardWidgets } from './helpers';

const intitialState = {
    loaders: {},
    widgetList: [],
    mqttTopics: [],
    apiResponse: [],
    dashboardId: undefined,
    widgetDetails: {},
    dashboardWidgetIds: [],
    mqttConnectionStatus: false,
};

const commonDashboardSlice = createSlice({
    name: 'commonDashboard',
    initialState: intitialState,
    reducers: {
        setWidgetList: (state: RootState, action: PayloadAction<any>) => {
            setDashboardWidgets(state, action);
        },
        setMqttTopics: (state: RootState, action: PayloadAction<string>) => {
            // const stateClone = current(state);
            // const alreadyPresentMqttTopic = stateClone['mqttTopics']?.find((topic: any) => topic === action.payload);
            // if (!alreadyPresentMqttTopic) {
            //     state['mqttTopics'].push(action.payload);
            // }
            state['mqttTopics'] = action.payload;
        },
        resetDashboard: () => {
            return intitialState;
        },
        setDashboardId: (state: RootState, action: PayloadAction<any>) => {
            state.dashboardId = action.payload;
        },

        setDashboardWidgetIds: (state: RootState, action: PayloadAction<any>) => {
            state.dashboardWidgetIds.push(action.payload);
        },
        setSummaryResponse: (state: RootState, action: PayloadAction<any>) => {
            apiResponseHelper(state, action);
        },
        configureSummaryValues: (state: RootState, action: PayloadAction<any>) => {
            const stateClone = current(state);
            if (stateClone['widgetList']?.length) {
                const selectedIndex = stateClone?.widgetList?.findIndex(
                    (item: any) => item?.id === action.payload.widgetId
                );
                if (stateClone['widgetList']?.[selectedIndex]?.['config']) {
                    state['widgetList'][selectedIndex]['config'] = action.payload.config;
                }
            }
        },
        setSummaryWidgetDetailsLoader: (state: RootState, action: PayloadAction<any>) => {
            state['loaders'][action.payload.key] = action.payload.value;
        },
        removeSummaryWidgetLoader: (state: RootState, action: PayloadAction<any>) => {
            delete state['loaders'][action.payload];
        },
    },
});

export const {
    setWidgetList,
    setMqttTopics,
    setDashboardId,
    resetDashboard,
    setSummaryResponse,
    setDashboardWidgetIds,
    configureSummaryValues,
    removeSummaryWidgetLoader,
    setSummaryWidgetDetailsLoader,
} = commonDashboardSlice.actions;

export const mqttTopics = (state: RootState): string[] => state.commonDashboard.mqttTopics;
export const widgetList = (state: RootState): any => state.commonDashboard.widgetList;
export const mqttCredentials = (state: RootState): any => state.commonDashboard.mqttCredentials;
export const dashboardLayout = (state: RootState): any => state.commonDashboard.dashboardLayout;

export default commonDashboardSlice;
