import React from 'react';
import { Divider, Paper, Stack } from '@mui/material';
import { WidgetFooter, WidgetHeader } from 'pages/WidgetManagement/common';
import ScheduleData from './ScheduleData';
import { CustomEmptyState } from 'components/CustomEmptyState';
import { Schedule } from '@mui/icons-material';

const getIds = (data: any): any => {
    let ids: any = [];
    data?.forEach((item: any) => {
        ids = [...ids, ...(item?.deviceIds ?? [])];
    });
    return ids
        ?.filter((item: any, index: number, array: any[]) => index === array?.findIndex((t: any) => t?.id === item?.id))
        ?.map((item: any) => ({ ...item, deviceName: item?.name, deviceId: item?.id }));
};

const Component = (props: any): JSX.Element => (
    <Paper sx={{ height: '100%' }}>
        {props?.widgetData?.viewTitleBar && (
            <WidgetHeader
                mode={props?.mode}
                widgetData={props?.widgetData}
                count={
                    props?.widgetData?.multiDevice
                        ? props?.widgetData?.filters?.deviceId?.length
                        : props?.widgetData?.total
                }
            />
        )}
        {props?.mode !== 'edit' && <Divider />}
        <Stack
            sx={{
                height: `calc(100% - ${props?.mode === 'edit' || props?.widgetData?.viewAllButton ? '97px' : '49px'})`,
                overflow: 'auto',
            }}
        >
            {props?.widgetData?.secondary?.length ? (
                <ScheduleData
                    data={props?.widgetData?.secondary}
                    maxCount={props?.widgetData?.maxCount}
                    detailRoute={props?.widgetData?.detailRoute}
                    mode={props?.mode}
                />
            ) : (
                <CustomEmptyState
                    className="padding-y-96"
                    icon={<Schedule fontSize={'inherit'} />}
                    title={'No Data Found'}
                />
            )}
        </Stack>
        {props?.widgetData?.viewAllButton && (
            <WidgetFooter
                mode={props?.mode ?? ''}
                widgetData={props?.widgetData}
                ids={getIds(props?.widgetData?.secondary)}
                disabled={!props?.widgetData?.secondary?.length}
            />
        )}
    </Paper>
);

export default Component;
