import React from 'react';

import { useConfirm } from '@fiji/common/src/hooks';
import { CustomModal } from 'components';
import { ModalActions } from 'components/CustomModal';
import { CloneWidgetModal } from '../WidgetList';
import { FormControlLabel, List, ListItem, Radio, Typography } from '@mui/material';
import { useAppDispatch, useTypedSelector } from '@fiji/common';
import { setSaveModalType } from '@fiji/common/src/features/widgetManagement/widgetConfigurationSlice';
import { useWidgetConfigHandlers } from '../common';

export const SaveOverrideModal = React.forwardRef((_, ref): JSX.Element => {
    const dispatch = useAppDispatch();

    const {
        handleSaveButton,
        details: { widgetDetails },
    } = useWidgetConfigHandlers();
    const { isVisible, onCancel, onConfirm, onClick } = useConfirm((): Promise<void> => handleSaveButton(onCancel));
    const saveAsModalRef = React.useRef<any>(null);

    const saveModalType = useTypedSelector((state) => state['widgetConfiguration']['saveModalType']);

    const modalActions: ModalActions[] = [
        {
            key: 'cancel',
            label: 'Cancel',
            variant: 'outlined',
            close: true,
            handleClick: (): void => {
                onCancel();
                dispatch(setSaveModalType(undefined));
            },
        },
        {
            key: 'save',
            label: 'Save',
            variant: 'contained',
            disabled: !saveModalType,
            handleClick: (): void => {
                if (saveModalType === 'update') {
                    onConfirm();
                } else {
                    onCancel();
                    saveAsModalRef?.current?.handleModalAction(true, widgetDetails);
                }
            },
        },
    ];

    React.useImperativeHandle(
        ref,
        () => ({
            openSaveModal: onClick,
        }),
        []
    );

    return (
        <>
            <CustomModal actions={modalActions} header="Save Widget" actionsDivider isOpen={isVisible} type="primary">
                <Typography variant="subtitle1" fontWeight={'600'} my={2}>
                    The Widget’s datasource has been changed. Would you like to:
                </Typography>

                <List className="margin-top-18">
                    <ListItem disablePadding>
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={saveModalType === 'saveAs'}
                                    onChange={(): void => dispatch(setSaveModalType('saveAs'))}
                                />
                            }
                            label={
                                <Typography variant="body1" className="black-500">
                                    Save as a new widget with a new name.
                                </Typography>
                            }
                            className="f-14 fw-400"
                        />
                    </ListItem>
                    <ListItem disablePadding>
                        <FormControlLabel
                            className="f-14 fw-400"
                            control={
                                <Radio
                                    checked={saveModalType === 'update'}
                                    onChange={(): void => dispatch(setSaveModalType('update'))}
                                />
                            }
                            label={
                                <Typography variant="body1" className="black-500">
                                    Overright the existing widget.
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>
            </CustomModal>
            <CloneWidgetModal ref={saveAsModalRef} modalType="save" />
        </>
    );
});
