import React from 'react';

import { useTheme } from '@mui/material/styles';
import { CloneWidgetModal } from '../WidgetList';
import { useWidgetConfigHandlers } from '../common';
import { AppBar, Button, CircularProgress, Stack, Toolbar, Typography } from '@mui/material';
import { CustomModal, CustomTransComponent } from 'components';
import { ModalActions } from 'components/CustomModal';
import { useConfirm } from '@fiji/common/src/hooks';
import { SaveOverrideModal } from './SaveOverrideModal';
import { useTypedSelector } from '@fiji/common';
import { getWidgetTypeValidationConfig } from '@fiji/common/src/utils/helpers';

export const WidgetConfigurationHeader = (): JSX.Element => {
    const saveAsModalRef = React.useRef<any>(null);
    const saveModalRef = React.useRef<any>(null);
    const theme: any = useTheme();
    const configurationPayload = useTypedSelector((state) => state['widgetConfiguration']);
    const {
        handleSaveButton,
        handleCloseButton,
        updateLoader,
        details: { widgetDetails },
    } = useWidgetConfigHandlers();

    const { isVisible, onClick, onCancel, onConfirm } = useConfirm(handleCloseButton);

    const selectedSource = useTypedSelector((state) => state.widgetConfiguration.source);

    const modalActions: ModalActions[] = [
        {
            key: '#exit',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            variant: 'outlined',
            close: true,
            handleClick: onCancel,
        },
        {
            key: '#closeEditor',
            label: <CustomTransComponent translationKey={'WIDGETS:CLOSE_WARN_MODAL.CLOSE_EDITOR'} />,
            variant: 'contained',
            handleClick: (): void => {
                onConfirm();
            },
        },
    ];
    const defaultConfigHelper = (key: any): boolean =>
        !widgetDetails?.config?.[key]?.every((channelData: any) => {
            const alreadyPresentChannel = configurationPayload?.data?.[key]?.find(
                (item: any) => item?.deviceId === channelData?.deviceId
            );
            return (
                alreadyPresentChannel &&
                channelData?.channels?.every((channel: any) =>
                    alreadyPresentChannel?.channels?.find(
                        (subChannel: any) => subChannel?.channelId === channel?.channelId
                    )
                ) &&
                alreadyPresentChannel?.channels?.length === channelData?.channels?.length
            );
        });

    const preferenceHelper = (): boolean => {
        switch (widgetDetails?.widgetType?.id) {
            case 'charts':
            case 'trends':
                return (
                    configurationPayload?.data?.maxCount !== widgetDetails?.config?.maxCount ||
                    configurationPayload?.data?.type !== widgetDetails?.config?.type ||
                    configurationPayload?.data?.duration !== widgetDetails?.config?.duration
                );
            case 'loads':
                return (
                    configurationPayload?.data?.maxCount !== widgetDetails?.config?.maxCount ||
                    configurationPayload?.data?.isTotalCountVisible !== widgetDetails?.config?.isTotalCountVisible ||
                    configurationPayload?.data?.countMode !== widgetDetails?.config?.countMode ||
                    configurationPayload?.data?.viewAllButton !== widgetDetails?.config?.viewAllButton
                );
            case 'gauge':
                return (
                    configurationPayload?.data?.viewScale !== widgetDetails?.config?.viewScale ||
                    configurationPayload?.data?.type !== widgetDetails?.config?.type ||
                    configurationPayload?.data?.viewThreshold !== widgetDetails?.config?.viewThreshold
                );

            case 'details':
                return (
                    configurationPayload?.data?.viewAllButton !== widgetDetails?.config?.viewAllButton ||
                    configurationPayload?.data?.secondaryChannelCount !==
                        widgetDetails?.config?.secondaryChannelCount ||
                    configurationPayload?.data?.isTotalCountVisible !== widgetDetails?.config?.isTotalCountVisible
                );

            case 'settings':
            case 'properties':
                return (
                    configurationPayload?.data?.viewAllButton !== widgetDetails?.config?.viewAllButton ||
                    configurationPayload?.data?.secondaryChannelCount !==
                        widgetDetails?.config?.secondaryChannelCount ||
                    configurationPayload?.data?.isTotalCountVisible !== widgetDetails?.config?.isTotalCountVisible ||
                    configurationPayload?.data?.countMode !== widgetDetails?.config?.countMode
                );
            case 'map':
                return (
                    configurationPayload?.data?.viewAllButton !== widgetDetails?.config?.viewAllButton ||
                    configurationPayload?.data?.isPinned !== widgetDetails?.config?.isPinned
                );
            case 'group_list':
            case 'device_list':
                return (
                    configurationPayload?.data?.countMode !== widgetDetails?.config?.countMode ||
                    configurationPayload?.data?.viewAllButton !== widgetDetails?.config?.viewAllButton ||
                    configurationPayload?.data?.maxCount !== widgetDetails?.config?.maxCount ||
                    configurationPayload?.data?.isTotalCountVisible !== widgetDetails?.config?.isTotalCountVisible ||
                    JSON.stringify(configurationPayload?.data?.sortPayload) !==
                        JSON.stringify(widgetDetails?.config?.sortPayload)
                );

            case 'timeline':
                return (
                    configurationPayload?.data?.showSeverityCount !== widgetDetails?.config?.showSeverityCount ||
                    configurationPayload?.data?.maxCount !== widgetDetails?.config?.maxCount
                );
            case 'command_bar':
                return (
                    configurationPayload?.data?.isPinned !== widgetDetails?.config?.isPinned ||
                    configurationPayload?.data?.viewTitleBar !== widgetDetails?.config?.viewTitleBar ||
                    configurationPayload?.data?.isTotalCountVisible !== widgetDetails?.config?.isTotalCountVisible
                );
            case 'virtual_loads':
                return (
                    configurationPayload?.data?.viewAllButton !== widgetDetails?.config?.viewAllButton ||
                    configurationPayload?.data?.isTotalCountVisible !== widgetDetails?.config?.isTotalCountVisible ||
                    configurationPayload?.data?.maxCount !== widgetDetails?.config?.maxCount
                );
            case 'schedule':
                return (
                    configurationPayload?.data?.viewTitleBar !== widgetDetails?.config?.viewTitleBar ||
                    configurationPayload?.data?.viewAllButton !== widgetDetails?.config?.viewAllButton ||
                    configurationPayload?.data?.maxCount !== widgetDetails?.config?.maxCount
                );
            default:
                return false;
        }
    };

    const chartConfigHandler = (): boolean =>
        !widgetDetails?.config?.secondary?.every((channel: any) =>
            configurationPayload?.data?.secondary?.find(
                (item: any) => item?.channelId === channel?.channelId && item?.deviceId && channel?.deviceId
            )
        );

    const isConfigChanged = (): boolean => {
        switch (widgetDetails?.widgetType?.id) {
            case 'details':
                return widgetDetails?.config?.primary || widgetDetails?.config?.secondary
                    ? defaultConfigHelper('primary') ||
                          defaultConfigHelper('secondary') ||
                          widgetDetails?.config?.secondary?.length !== configurationPayload?.data?.secondary?.length ||
                          widgetDetails?.config?.primary?.length !== configurationPayload?.data?.primary?.length ||
                          preferenceHelper()
                    : preferenceHelper() ||
                          configurationPayload?.data?.secondary?.length ||
                          configurationPayload?.data?.primary?.length;
            case 'settings':
            case 'properties':
            case 'command_bar':
                return widgetDetails?.config?.secondary
                    ? defaultConfigHelper('secondary') ||
                          widgetDetails?.config?.secondary?.length !== configurationPayload?.data?.secondary?.length ||
                          preferenceHelper()
                    : configurationPayload?.data?.secondary?.length || preferenceHelper();
            case 'trends':
            case 'charts':
                return widgetDetails?.config?.secondary
                    ? chartConfigHandler() ||
                          widgetDetails?.config?.secondary?.length !== configurationPayload?.data?.secondary?.length ||
                          preferenceHelper()
                    : configurationPayload?.data?.secondary?.length || preferenceHelper();

            case 'gauge':
                return widgetDetails?.config?.data
                    ? widgetDetails?.config?.data?.channelId !== configurationPayload?.data?.data?.channelId ||
                          preferenceHelper()
                    : configurationPayload?.data?.data?.channelId || preferenceHelper();

            case 'loads':
                return widgetDetails?.config?.secondary
                    ? defaultConfigHelper('secondary') || preferenceHelper()
                    : preferenceHelper() || configurationPayload?.data?.secondary?.length;
            case 'group_list':
            case 'device_list':
            case 'timeline':
                return widgetDetails?.config?.secondary
                    ? preferenceHelper()
                    : preferenceHelper() || configurationPayload?.data?.selectedNodes;

            case 'map':
                return widgetDetails?.config?.secondary
                    ? preferenceHelper()
                    : preferenceHelper() ||
                          configurationPayload?.data?.selectedNodes ||
                          configurationPayload?.data?.filters['deviceStatus']?.length ||
                          configurationPayload?.data?.filters['modelCategory']?.length;

            case 'virtual_loads':
                return preferenceHelper();

            case 'schedule':
                return widgetDetails?.config?.secondary
                    ? preferenceHelper()
                    : preferenceHelper() || configurationPayload?.data?.selectedNodes;
            default:
                return true;
        }
    };

    return (
        <>
            <AppBar position={'static'}>
                <Toolbar className="bg-white text-black padding-5">
                    <Stack className="w-100">
                        <Stack direction="row" spacing={1} alignItems={'center'} justifyContent={'space-between'}>
                            <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} width={'100%'}>
                                <Button
                                    sx={{
                                        border: `1px solid ${theme?.palette?.primary?.main}`,
                                        '&:hover': {
                                            backgroundColor: theme?.palette?.primary?.[50],
                                        },
                                    }}
                                    variant="outlined"
                                    onClick={isConfigChanged() ? onClick : handleCloseButton}
                                >
                                    {<CustomTransComponent translationKey={'COMMON:CLOSE'} />}
                                </Button>
                                <Button
                                    sx={{
                                        border: `1px solid ${theme?.palette?.primary?.main}`,
                                        '&:hover': {
                                            backgroundColor: theme?.palette?.primary?.[50],
                                        },
                                    }}
                                    variant="outlined"
                                    onClick={(): void => {
                                        saveAsModalRef?.current?.handleModalAction(true, {
                                            ...widgetDetails,
                                            config: configurationPayload?.data,
                                        });
                                    }}
                                >
                                    <CustomTransComponent translationKey={'WIDGETS:SAVE_AS_LABEL'} />
                                </Button>
                                <Button
                                    sx={{
                                        backgroundColor: theme?.palette?.primary?.main,
                                        '&:hover': {
                                            backgroundColor: theme?.palette?.primary?.main,
                                        },
                                    }}
                                    {...(updateLoader && {
                                        startIcon: <CircularProgress color="inherit" size={20} />,
                                    })}
                                    variant="contained"
                                    disabled={!isConfigChanged() || updateLoader}
                                    onClick={
                                        getWidgetTypeValidationConfig(widgetDetails?.widgetType?.id)
                                            ?.isSourceRequired && widgetDetails?.sourceId !== selectedSource?.id
                                            ? saveModalRef?.current?.openSaveModal
                                            : handleSaveButton
                                    }
                                >
                                    {updateLoader ? (
                                        'Saving...'
                                    ) : (
                                        <CustomTransComponent translationKey={'COMMON:SAVE_LABEL'} />
                                    )}
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Toolbar>
            </AppBar>

            <CustomModal
                key="#closeModal"
                actions={modalActions}
                isOpen={isVisible}
                type="primary"
                actionsDivider
                header={<CustomTransComponent translationKey={'WIDGETS:CLOSE_WARN_MODAL.HEADER'} />}
            >
                <Typography variant="subtitle2">
                    <CustomTransComponent translationKey={'WIDGETS:CLOSE_WARN_MODAL.DESCRIPTION'} />
                </Typography>
            </CustomModal>

            <CloneWidgetModal ref={saveAsModalRef} modalType="save" />

            <SaveOverrideModal key="ssve1d" ref={saveModalRef} />
        </>
    );
};
