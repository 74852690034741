import React, { useEffect, useRef } from 'react';

import Highcharts from 'highcharts';
import SolidGauge from 'highcharts/modules/solid-gauge';
import HighchartsMore from 'highcharts/highcharts-more';
import { WidgetHeader } from 'pages/WidgetManagement/common';
import HighchartsReact from 'highcharts-react-official';
import { Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import { HorizontalGauge } from './HorizontalGauge';
import { GroupPath } from 'components';
import { useTransformData } from 'hooks/useTransformData';

HighchartsMore(Highcharts);
SolidGauge(Highcharts);

type Gauge = {
    type: 'kpi' | 'solidGauge' | 'speedoMeter' | 'horizontal';
    data: any;
    multiDevice: boolean;
    threshold?: any;
    viewThreshold?: boolean;
    min: number;
    max: number;
};

export const getPlotLineValue = (value: number, multipleBy: number): number => (value / 10) * multipleBy;

const KpiWidget = ({ gaugeWidget, pointConversion }: { gaugeWidget: any; pointConversion: any }): JSX.Element => {
    const kpiRef: any = useRef(null);

    const [option, setOption] = React.useState({
        chart: {
            type: 'solidgauge',
            height: '50%',
        },
        credits: {
            enabled: false,
        },
        title: {
            text: '',
        },
        tooltip: {
            borderWidth: 0,
            backgroundColor: 'none',
            shadow: false,
            style: {
                fontSize: '16px',
            },
            pointFormat:
                '<span color: "#727E84">{series.name}</span><br>' +
                '<span style="font-size: 25px; color: #727E84;' +
                'font-weight: bold">{point.y}</span> <span style="font-size:16px font-weight: 400 color: #727E84">°C</span>',
            positioner: (labelWidth: number): Highcharts.PositionObject => ({
                x: (kpiRef?.current?.chart?.chartWidth || 0 - labelWidth) / 2.5,
                y: (kpiRef?.current?.chart?.plotHeight || 0) / 2 - 20,
            }),
        },

        pane: {
            startAngle: 0,
            endAngle: 360,
            background: [
                {
                    outerRadius: '112%',
                    innerRadius: '70%',
                    backgroundColor: '#E7F6F4',
                    borderWidth: 0,
                },
            ],
        },

        yAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickPositions: [],
        },

        plotOptions: {
            solidgauge: {
                dataLabels: {
                    enabled: false,
                },
                linecap: 'round',
                stickyTracking: false,
                rounded: true,
            },
        },
        series: [
            {
                type: 'solidgauge',
                name: 'Temperature',
                data: [
                    {
                        color: '#57C141',
                        radius: '112%',
                        innerRadius: '70%',
                        y: 0,
                    },
                ],
                custom: {
                    icon: 'filter',
                    iconColor: '#000000',
                },
            },
        ],
    });
    useEffect(() => {
        const renderIcons = (): void => {
            kpiRef.current?.chart?.series?.forEach((series: any): void => {
                if (series?.options?.custom) {
                    const icon = kpiRef.current?.chart?.renderer
                        .text(`<i class="fa-solid fa-temperature-three-quarters"></i>`, 0, 0, true)
                        .attr({
                            zIndex: 10,
                        })
                        .css({
                            color: series.options.custom.iconColor ?? 'black',
                            fontSize: '1.5em',
                        });
                    if (icon) {
                        series.customIcon = icon;
                    }
                }
                if (series?.customIcon && series.points[0]?.shapeArgs) {
                    series?.customIcon.attr({
                        x: (kpiRef.current?.chart?.chartWidth || 0) / 2 - 15,
                        y:
                            (kpiRef.current?.chart?.plotHeight || 0) / 2 -
                            ((series.points[0].shapeArgs.innerR ?? 0) -
                                ((series.points[0].shapeArgs.r ?? 0) - (series.points[0].shapeArgs.innerR ?? 0)) / 2) +
                            8,
                    });
                }
            });
        };
        if (kpiRef?.current && gaugeWidget?.type === 'kpi') {
            kpiRef.current?.chart?.update({
                chart: {
                    events: {
                        render: renderIcons,
                    },
                },
                tooltip: {
                    positioner: (labelWidth: number): Highcharts.PositionObject => ({
                        x: (kpiRef?.current?.chart?.chartWidth || 0 - labelWidth) / 2.5,
                        y: (kpiRef?.current?.chart?.plotHeight || 0) / 2 - 20,
                    }),
                },
                yAxis: {
                    min: gaugeWidget?.min ?? 0,
                    max: gaugeWidget?.max ?? 90,
                },
            });
        }
    }, [option]);

    useEffect(() => {
        if (kpiRef?.current || gaugeWidget?.data) {
            const cloneOption = JSON.parse(JSON.stringify(option));
            cloneOption['series'] = [
                {
                    name: gaugeWidget.data?.displayName,
                    data: [gaugeWidget?.data?.value ?? 0],
                    tooltip: {
                        valueSuffix: gaugeWidget?.data?.unit,
                    },
                    dataLabels: {
                        format: `<div style="text-align:center">
                                <span style="font-size:25px; font-weight: 400">${pointConversion?.(
                                    gaugeWidget?.data?.value
                                )}</span> <span style="color: #727E84; font-weight: 400, font-size:2px;">${
                            gaugeWidget?.data?.unit
                        }</span>
                                </div>`,
                        borderWidth: 0,
                    },
                },
            ];
            cloneOption['yAxis'] = {
                ...(option?.yAxis ?? {}),
                min: gaugeWidget?.min ?? 0,
                max: gaugeWidget?.max ?? gaugeWidget?.data?.value,
            };
            setOption(cloneOption);
        }
    }, [gaugeWidget?.data]);
    return <HighchartsReact highcharts={Highcharts} options={option} ref={kpiRef} />;
};

const SpeedometerWidget = ({
    gaugeWidget,
    pointConversion,
}: {
    gaugeWidget: any;
    pointConversion: any;
}): JSX.Element => {
    const speedometerRef: any = useRef(null);
    const [option, setOption] = React.useState({
        chart: {
            type: 'gauge',
            plotBackgroundColor: undefined,
            plotBackgroundImage: undefined,
            plotBorderWidth: 0,
            plotShadow: false,
            height: '80%',
        },
        title: '',
        pane: {
            startAngle: -90,
            endAngle: 89.9,
            background: undefined,
            center: ['50%', '75%'],
            size: '100%',
        },
        yAxis: {
            min: 0,
            max: 90,
            tickPixelInterval: 72,
            tickPosition: 'inside',
            tickColor: Highcharts.defaultOptions.chart?.backgroundColor || '#FFFFFF',
            tickLength: 70,
            tickWidth: 2,
            minorTickInterval: null,
            labels: {
                distance: 20,
                enabled: false,
                style: {
                    fontSize: '14px',
                },
            },
            lineWidth: 0,
        },
        series: [
            {
                name: 'Speed',
                type: 'gauge',
                data: [0],
                tooltip: {
                    valueSuffix: '°C',
                },
                dataLabels: {
                    format:
                        '<div style="text-align:center">' +
                        '<span style="font-size:25px;font-weight: 400">{y}</span>' +
                        '</div>',
                    borderWidth: 0,
                },
                dial: {
                    radius: '80%',
                    backgroundColor: 'gray',
                    baseWidth: 15,
                    baseLength: '0%',
                    rearLength: '0%',
                },
                pivot: {
                    backgroundColor: 'gray',
                    radius: 6,
                },
            },
        ],
        credits: {
            enabled: false,
        },
    });

    // const getThresholdValue = ():any =>

    useEffect(() => {
        if (speedometerRef?.current || gaugeWidget?.data) {
            const isThreshold =
                Boolean(Object?.keys(gaugeWidget?.threshold ?? {}).length) &&
                Object?.values(gaugeWidget?.threshold ?? {}).some((item: any) => Boolean(item));
            const cloneOption = JSON.parse(JSON.stringify(option));
            cloneOption['series'] = [
                {
                    name: gaugeWidget.data?.displayName,
                    data: [gaugeWidget?.data?.value ?? 0],
                    tooltip: {
                        valueSuffix: gaugeWidget?.data?.unit,
                    },
                    dataLabels: {
                        format: `<div style="text-align:center">
                                <span style="font-size:25px; font-weight: 400">${pointConversion?.(
                                    gaugeWidget?.data?.value
                                )}</span> <span style="color: #727E84; font-weight: 400, font-size:2px;">${
                            gaugeWidget?.data?.unit
                        }</span>
                                </div>`,
                        borderWidth: 0,
                    },
                },
            ];

            cloneOption['yAxis'] = {
                ...(option?.yAxis ?? {}),
                labels: {
                    ...option?.yAxis?.labels,
                    enabled: gaugeWidget?.viewScale ?? false,
                },
                min: gaugeWidget?.min ?? 0,
                max: gaugeWidget?.max ?? gaugeWidget?.data?.value,
                plotBands: [
                    {
                        from: 0,
                        to: gaugeWidget?.threshold?.lowCritical ?? 0,
                        color: gaugeWidget?.viewThreshold && isThreshold ? '#DF5353' : '#55BF3B',
                        thickness: 70,
                    },
                    {
                        from: gaugeWidget?.threshold?.lowCritical ?? 0,
                        to: gaugeWidget?.threshold?.lowWarning ?? 0,
                        color: gaugeWidget?.viewThreshold && isThreshold ? '#DDDF0D' : '#55BF3B',
                        thickness: 70,
                    },
                    {
                        from: gaugeWidget?.threshold?.lowWarning ?? 0,
                        to: gaugeWidget?.threshold?.highWarning ?? 0,
                        color: '#55BF3B',
                        thickness: 70,
                    },
                    {
                        from: gaugeWidget?.threshold?.highWarning ?? gaugeWidget?.data?.value ?? 0,
                        to: gaugeWidget?.threshold?.highCritical ?? gaugeWidget?.data?.value ?? 0,
                        color: gaugeWidget?.viewThreshold && isThreshold ? '#DDDF0D' : '#55BF3B',
                        thickness: 70,
                    },
                    {
                        from: gaugeWidget?.threshold?.highCritical ?? gaugeWidget?.data?.value ?? 0,
                        to: (gaugeWidget?.max || gaugeWidget?.data?.value) ?? 0,
                        color: gaugeWidget?.viewThreshold && isThreshold ? '#DF5353' : '#55BF3B',
                        thickness: 70,
                    },
                ],
            };
            setOption(cloneOption);
        }
    }, [gaugeWidget]);
    return <HighchartsReact highcharts={Highcharts} options={option} ref={speedometerRef} />;
};

const Component = ({ widgetData: gaugeWidget, mode }: { widgetData: Gauge; mode: string }): JSX.Element => {
    const { pointConversion } = useTransformData();
    const gaugeRef: any = useRef(null);

    const [option, setOption] = React.useState<any>({
        chart: {
            type: 'solidgauge',
        },
        title: '',
        pane: {
            center: ['50%', '85%'],
            size: '100%',
            startAngle: -90,
            endAngle: 90,
            background: [
                {
                    backgroundColor: Highcharts?.defaultOptions?.legend?.backgroundColor ?? '#EEE',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc',
                },
            ],
        },
        exporting: {
            enabled: false,
        },
        tooltip: {
            enabled: false,
        },
        yAxis: {
            stops: [
                [0.1, '#55BF3B'],
                [0.5, '#55BF3B'],
                [0.9, '#55BF3B'],
            ],
            min: 0,
            max: 100,
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            tickAmount: 2,
            title: {
                text: undefined,
                y: -70,
            },
            labels: {
                y: 16,
            },
            plotLines: [
                {
                    color: 'white',
                    width: 2,
                    value: 60,
                    zIndex: 5,
                },
                {
                    color: 'white',
                    width: 2,
                    value: 80,
                    zIndex: 5,
                },
            ],
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                name: 'Temperature',
                data: [0],
                dataLabels: {
                    format:
                        '<div style="text-align:center">' +
                        '<span style="font-size:25px;font-weight: 400"">{y}</span> <span style="font-size:16px; font-weight: 400; color: #727E84">°C</span>' +
                        '</div>',
                },
                tooltip: {
                    valueSuffix: '°C',
                },
            },
        ],
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true,
                },
            },
        },
    });

    useEffect(() => {
        if (gaugeRef?.current && gaugeWidget?.data) {
            const cloneOption = JSON.parse(JSON.stringify(option));
            cloneOption['series'] = [
                {
                    name: gaugeWidget.data?.displayName,
                    data: [gaugeWidget?.data?.value ?? 0],
                    tooltip: {
                        valueSuffix: gaugeWidget?.data?.unit,
                    },
                    dataLabels: {
                        format: `<div style="text-align:center">
                                <span style="font-size:25px; font-weight: 400">${pointConversion?.(
                                    gaugeWidget?.data?.value
                                )}</span> <span style="color: #727E84; font-weight: 400, font-size:2px;">${
                            gaugeWidget?.data?.unit
                        }</span>
                                </div>`,
                        borderWidth: 0,
                    },
                },
            ];
            cloneOption['yAxis'] = {
                ...(option?.yAxis ?? {}),
                ...(gaugeWidget?.viewThreshold
                    ? {
                          stops: [
                              [0.1, '#55BF3B'],
                              [(gaugeWidget?.threshold?.highWarning ?? 0) / gaugeWidget?.max || 0.9, '#DDDF0D'],
                              [(gaugeWidget?.threshold?.highCritical ?? 0) / gaugeWidget?.max || 0.9, '#DF5353'],
                          ],
                      }
                    : {
                          stops: [
                              [0.1, '#55BF3B'],
                              [0.5, '#55BF3B'],
                              [0.9, '#55BF3B'],
                          ],
                      }),
                min: gaugeWidget?.min ?? 0,
                max: gaugeWidget?.max ?? gaugeWidget?.data?.value,
                plotLines: [
                    {
                        color: 'white',
                        width: 2,
                        value:
                            gaugeWidget?.threshold?.highWarning ||
                            getPlotLineValue(gaugeWidget?.max ?? gaugeWidget?.data?.value, 6),
                        zIndex: 5,
                    },
                    {
                        color: 'white',
                        width: 2,
                        value:
                            gaugeWidget?.threshold?.highCritical ||
                            getPlotLineValue(gaugeWidget?.max ?? gaugeWidget?.data?.value, 8),
                        zIndex: 5,
                    },
                ],
            };
            setOption(cloneOption);
        }
    }, [gaugeWidget?.data]);

    const getChart = (): JSX.Element => {
        if (gaugeWidget?.type === 'kpi') {
            return <KpiWidget gaugeWidget={gaugeWidget} pointConversion={pointConversion} />;
        } else if (gaugeWidget?.type === 'speedoMeter') {
            return <SpeedometerWidget gaugeWidget={gaugeWidget} pointConversion={pointConversion} />;
        } else if (gaugeWidget?.type === 'horizontal') {
            return <HorizontalGauge gaugeWidget={gaugeWidget} pointConversion={pointConversion} />;
        }
        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={option}
                ref={gaugeRef}
                pointConversion={pointConversion}
            />
        );
    };

    return (
        <Card sx={{ height: '100%', boxShadow: 'none' }}>
            <WidgetHeader widgetData={gaugeWidget} mode={mode} />
            {mode !== 'edit' && <Divider />}
            <CardContent className="padding-0">
                <Stack p={2}>
                    <Stack display="flex" direction="column">
                        <Typography variant="h6" fontWeight="600" fontSize="20px">
                            {gaugeWidget?.data?.displayName}
                        </Typography>
                        {gaugeWidget?.multiDevice && (
                            <GroupPath
                                deviceName={gaugeWidget?.data?.deviceName}
                                pathName={gaugeWidget?.data?.devicePath ?? []}
                            />
                        )}
                    </Stack>
                    {getChart()}
                </Stack>
            </CardContent>
        </Card>
    );
};

export default Component;
