import dayjs from 'dayjs';
import { RootState } from '../../app/store';
import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { handleActionSelection, holidaysHandler } from './helpers';

const initialState = {
    actions: [],
    endtime: new Date()?.toISOString(),
    timezone: undefined,
    groupIds: [],
    deviceIds: [],
    isLoading: false,
    startTime: new Date()?.toISOString(),
    deviceTypeId: undefined,
    globalSearch: undefined,
    selectedAction: undefined,
    startDate: new Date()?.toISOString(),
    isDeviceSelectable: false,
    selectedFrequencyTab: 'once',
    isActionEndTimeRequired: false,
    actionsGlobalSearchKey: undefined,
    repeatForever: true,
    repeatUntil: false,
    repeat: false,
    frequency: 'DAILY',
    repeatEveryDays: 7,
    repeatEveryWeek: undefined,
    repeatEveryMonth: undefined,
    repeatEveryYear: undefined,
    repeatOccurrence: undefined,
    repeatDay: true,
    repeatEveryOfEvery: false,
    repeatOnDay: undefined,
    repeatMonthDay: new Date()?.toISOString(),
    repeatOn: 'DAY',
    repeatEvery: 'FIRST',
    weekDay: [],
    endDate: new Date()?.toISOString(),
    monthList: [dayjs()?.format('MMMM')?.toUpperCase()],
    exceptionType: 'manual',
    holidays: [],
    excludeHolidays: [],
    holidayList: [],
    selectedCountry: 'US',
    frequencyHolidayCountry: 'US',
    activeStep: 0,
};

const schedulerCrudSlice = createSlice({
    name: 'scheduleCrud',
    initialState: initialState,
    reducers: {
        resetCrudPayload: () => {
            return initialState;
        },
        setScheduleFrequencyTab: (state: RootState, action: PayloadAction<any>) => {
            state['selectedFrequencyTab'] = action.payload;
            state['frequency'] = action.payload?.toUpperCase();
        },
        setRepeatRadioPayload: (state: RootState, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
        toggleDeviceSelectionMode: (state: RootState) => {
            state['isDeviceSelectable'] = !state['isDeviceSelectable'];
        },
        setScheduleCrudDeviceTypeId: (state: RootState, action: PayloadAction<any>) => {
            state['deviceTypeId'] = action.payload;
        },
        setScheduleActions: (state: RootState, action: PayloadAction<any>) => {
            handleActionSelection(state, action);
        },
        setScheduleSelectedNodes: (state: RootState, action: PayloadAction<any>) => {
            const currentClone = current(state);

            if (Array.isArray(action.payload.value)) {
                state[action.payload.key] = action.payload.value;
            } else {
                const selectedIndex = currentClone?.[action.payload.key]?.findIndex(
                    (item: any) => item === action?.payload?.value
                );

                if (selectedIndex !== -1) {
                    state?.[action.payload.key]?.splice(selectedIndex, 1);
                } else {
                    state?.[action.payload.key]?.push(action.payload?.value);
                }
            }
        },
        selectScheduleTimeZone: (state: RootState, action: PayloadAction<any>) => {
            state['timezone'] = action.payload;
        },
        setStartOrEndTime: (state: any, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
            if (action.payload?.key === 'exceptionType') {
                state['excludeHolidays'] = [];
            }
        },

        toggleEndTimeRequirement: (state: RootState) => {
            state['isActionEndTimeRequired'] = !state['isActionEndTimeRequired'];
            if (!state['isActionEndTimeRequired']) {
                state['actions']?.forEach((item: any) => {
                    delete item['endValue'];
                });
            }
        },
        setSelectedAction: (state: RootState, action: PayloadAction<any>) => {
            state['selectedAction'] = action.payload;
        },
        replaceScheduleAction: (state: RootState, action: PayloadAction<any>) => {
            const stateClone = current(state);
            const selectedIndex = stateClone?.actions?.findIndex((item: any) => item?.key === action.payload.key);
            if (selectedIndex !== -1) {
                state['actions'].splice(selectedIndex, 1, action.payload?.value);
            }
        },
        setScheduleCrudHierachyGlobalSearch: (state: RootState, action: PayloadAction<any>) => {
            state['globalSearch'] = action.payload;
        },
        setScheduleCrudActionsGlobalSearch: (state: RootState, action: PayloadAction<any>) => {
            state['actionsGlobalSearchKey'] = action.payload;
        },
        setScheduleActionEndValue: (state: RootState, action: PayloadAction<any>) => {
            const stateClone = current(state);
            const selectedIndex = stateClone?.actions?.findIndex((item: any) => item?.key === action.payload);
            const selectedEntity = stateClone?.actions?.find((item: any) => item?.key === action.payload);
            if (selectedIndex !== -1) {
                if (!selectedEntity?.endValue) {
                    state.actions[selectedIndex] = { ...state?.actions?.[selectedIndex], endValue: 0 };
                } else {
                    delete state.actions[selectedIndex]['endValue'];
                }
            }
        },
        removeScheduleAction: (state: RootState, action: PayloadAction<any>) => {
            const stateClone = current(state);
            const selectedIndex = stateClone?.actions?.findIndex((item: any) => item.key === action.payload);
            if (selectedIndex !== -1) state['actions'].splice(selectedIndex, 1);
        },
        setScheduleActionStartEndValue: (state: RootState, action: PayloadAction<any>) => {
            const stateClone = current(state);
            const selectedIndex = stateClone?.actions?.findIndex((item: any) => item?.key === action.payload.actionId);
            if (selectedIndex !== -1) {
                state.actions[selectedIndex] = {
                    ...state?.actions?.[selectedIndex],
                    [action.payload.key]: action.payload.value,
                };
            }
        },
        setScheduleSelectedMonths: (state: RootState, action: PayloadAction<any>) => {
            const stateClone = current(state);
            const selectedIndex = stateClone['monthList']?.findIndex((month: any) => month === action.payload);

            if (selectedIndex !== -1) {
                state['monthList'].splice(selectedIndex, 1);
            } else {
                state['monthList'].push(action.payload);
            }
        },
        handleHolidays: (state: RootState, action: PayloadAction<any>) => {
            holidaysHandler(state, action, 'excludeHolidays');
        },
        handleFrequencyHolidays: (state: RootState, action: PayloadAction<any>) => {
            holidaysHandler(state, action, 'holidays');
        },
        resetHolidays: (state: RootState) => {
            state['exculdeHolidays'] = [];
        },
        setDynamicScheduleCrudPayload: (state: RootState, action: PayloadAction<any>) => {
            state[action.payload.key] = action.payload.value;
        },
        resetFrequencyPayload: (state: RootState) => {
            state['repeat'] = false;
            state['weekDay'] = [];
            state['endDate'] = new Date()?.toISOString();
            state['startDate'] = new Date()?.toISOString();
            state['repeatUntil'] = false;
            state['repeatForever'] = true;
            state['repeatEveryDays'] = undefined;
            state['repeatEveryWeek'] = undefined;
            state['repeatEveryMonth'] = undefined;
            state['repeatOnDay'] = undefined;
            state['repeatOccurrence'] = undefined;
            state['repeatEvery'] = 'FIRST';
            state['repeatDay'] = true;
            state['repeatEveryOfEvery'] = false;
            state['repeatMonthDay'] = new Date()?.toISOString();
            state['monthList'] = [dayjs()?.format('MMMM')?.toUpperCase()];
            state['repeatOn'] = 'DAY';
            state['holidayList'] = [];
            state['holidays'] = [];
        },
    },
});

export const {
    resetHolidays,
    handleHolidays,
    resetCrudPayload,
    setSelectedAction,
    setStartOrEndTime,
    setDynamicScheduleCrudPayload,
    setScheduleActions,
    removeScheduleAction,
    setRepeatRadioPayload,
    resetFrequencyPayload,
    replaceScheduleAction,
    selectScheduleTimeZone,
    setScheduleFrequencyTab,
    handleFrequencyHolidays,
    toggleEndTimeRequirement,
    setScheduleSelectedNodes,
    toggleDeviceSelectionMode,
    setScheduleSelectedMonths,
    setScheduleActionEndValue,
    setScheduleCrudDeviceTypeId,
    setScheduleActionStartEndValue,
    setScheduleCrudActionsGlobalSearch,
    setScheduleCrudHierachyGlobalSearch,
} = schedulerCrudSlice.actions;

export default schedulerCrudSlice;
