import React from 'react';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { Avatar, Chip, IconButton, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { CustomTable, CustomTransComponent, DateAndYearSkelton } from '../../../components';
import { useGetAllDashboardsQuery } from '@fiji/common/src/features/dashboardManagement/dashboardApi';
import Check from '@mui/icons-material/Check';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Dashboard from '@mui/icons-material/Dashboard';
import Delete from '@mui/icons-material/Delete';
import DriveFileRenameOutline from '@mui/icons-material/DriveFileRenameOutline';
import FileCopy from '@mui/icons-material/FileCopy';
import MoreVert from '@mui/icons-material/MoreVert';
import Search from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import CustomIcon from '../../../components/CustomIcon';
import { DeleteDashboardModal } from './DashboardDeleteModal';
import { useNavigate } from 'react-router-dom';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { EditDashboardDetails } from '../Common';
import { useFilteredValues } from '../../../hooks/useFilteredValues';
import { AssociateDeviceModal, CreateDashboardModal } from '../CreateDashboard';
import { toFirstLetterUpperCase } from '../../../utils/helpers';
import { useDebounce, useIsMount } from '@fiji/common/src/hooks';
import { CustomMenu } from 'components/CustomMenu';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useRBAC } from 'hooks';
import { useTransformData } from 'hooks/useTransformData';
import { InputWithCustomPlaceholder } from 'components/InputWithCustomPlaceholder';

export const DashboardList = (): JSX.Element => {
    const theme: any = useTheme();
    const currentOrg = useTypedSelector(selectedOrg);
    const permissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permissions);
    const canUpdateSystemDashboard = hasPermission('edit-system-dashboards');
    const canUpdateUserDashboard = hasPermission('edit-user-dashboards');
    const canDeleteSystemDashboard = hasPermission('delete-system-dashboards');
    const canDeleteUserDashboard = hasPermission('delete-user-dashboards');
    const canCreateSystemDashboard = hasPermission('create-system-dashboards');
    const canCreateUserDashboard = hasPermission('create-user-dashboards');
    const navigate = useNavigate();
    const [payload, setPayload] = React.useState({
        page: 0,
        size: 10,
        filters: {},
    });
    const [date, setDate] = React.useState<any>(null);
    const [searchKey, setSearchKey] = React.useState<any>('');

    const [, debouncedValue] = useDebounce(undefined, undefined, searchKey);
    const { convertDateTime } = useTransformData();
    const tableRef: any = React.useRef(null);
    const deleteRef: any = React.useRef(null);
    const editRef: any = React.useRef(null);
    const cloneRef: any = React.useRef(null);
    const associateDeviceRef: any = React.useRef(null);
    const isMount = useIsMount();

    const handleFilterChange = (filters: any, sortData?: any): void => {
        if (JSON.stringify(filters) !== JSON.stringify(payload.filters)) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                filters: { ...filters, ...(isMount ? payload.filters : {}) },
            }));
        } else if (sortData && Object.keys(sortData)?.length) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                sort: sortData,
            }));
        }
    };

    React.useEffect(() => {
        if (!isMount) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                searchKey: debouncedValue,
            }));
        }
    }, [debouncedValue]);

    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);
    const handleDashboardPageChange = (page: any, size: any): void => {
        setPayload((prev: any) => ({ ...prev, page: page, size: size }));
    };

    const getActionCell = (data: any): JSX.Element => (
        <Stack direction={'row'} alignItems={'center'} gap={1}>
            {((data?.isSystemDashboard && canUpdateSystemDashboard) ||
                (!data?.isSystemDashboard && canUpdateUserDashboard) ||
                (!data?.isSystemDashboard && canDeleteUserDashboard) ||
                (!data?.isSystemDashboard && canCreateUserDashboard) ||
                (data?.isSystemDashboard && canDeleteSystemDashboard) ||
                (data?.isSystemDashboard && canCreateSystemDashboard)) && <CustomMenu menuList={getActionsBtn(data)} />}
            {((data?.isSystemDashboard && canUpdateSystemDashboard) ||
                (!data?.isSystemDashboard && canUpdateUserDashboard)) && (
                <ChevronRight
                    key={data?.id}
                    onClick={(): void => {
                        navigate(`/${currentRealmName}/editDashboard/${data?.assignTypeIds[0]}/${data?.id}`, {
                            state: { assignType: data?.assignType, assignId: data?.assignTypeIds[0] },
                        });
                    }}
                    className="cursor-pointer"
                />
            )}
        </Stack>
    );
    const getAssignment = (data: any, key: string): JSX.Element =>
        !data?.isSystemDashboard ? (
            <>
                <Typography variant="body1">{data?.[key]?.name ?? '--'}</Typography>
                <Typography variant="body2">{data?.[key]?.email ?? '--'}</Typography>
            </>
        ) : (
            <Typography variant="body1">System</Typography>
        );

    const getUpdateTime = (data: any): JSX.Element =>
        data['updatedAt'] ? (
            <>
                <Typography variant="body1" fontSize={'14px'} fontWeight={'400'}>
                    {convertDateTime({
                        timestamp: data['updatedAt'],
                    })}
                </Typography>
                <Typography variant="body2" fontSize={'12px'} fontWeight={'400'}>
                    {`${convertDateTime({
                        timestamp: data['updatedAt'],
                        customFormat: 'HH:mm:ss',
                    })}`}
                </Typography>
            </>
        ) : (
            <>--</>
        );

    const getName = (data: any): JSX.Element => (
        <Stack direction={'row'} alignItems={'center'} gap={1}>
            <Avatar sx={{ bgcolor: '#EEF0F0', color: '#727E84' }}>
                <CustomIcon iconName={data['icon']?.web?.name} family={data['icon']?.web?.family} />
            </Avatar>
            <Typography variant="body1" fontSize={'14px'} fontWeight={'600'}>
                {data['name'] ?? '--'}
            </Typography>
        </Stack>
    );

    const getActionsBtn = (data: any): any => {
        const menuGroups = [];

        if (
            (data?.isSystemDashboard && canUpdateSystemDashboard) ||
            (!data?.isSystemDashboard && canUpdateUserDashboard)
        ) {
            menuGroups.push(
                {
                    icon: <DriveFileRenameOutline />,
                    title: <CustomTransComponent translationKey={'COMMON:EDIT_DETAILS'} />,
                    onClick: (): void => editRef?.current?.handleModalAction?.(true, data),
                },

                {
                    icon: <FileCopy />,
                    title: <CustomTransComponent translationKey={'COMMON:ASSOCIATE_DEVICES'} />,
                    className: data?.assignType === 'GROUP' ? 'disabled' : '',
                    onClick: (): void => associateDeviceRef?.current?.handleModalAction(true, data),
                }
            );
        }

        if (
            (data?.isSystemDashboard && canCreateSystemDashboard) ||
            (!data?.isSystemDashboard && canCreateUserDashboard)
        ) {
            menuGroups?.push({
                icon: <FileCopy />,
                title: <CustomTransComponent translationKey={'COMMON:CLONE'} />,
                onClick: (): void => cloneRef?.current?.handleModalAction?.(true, data),
            });
        }
        if (
            (data?.isSystemDashboard && canDeleteSystemDashboard) ||
            (!data?.isSystemDashboard && canDeleteUserDashboard)
        ) {
            menuGroups.push({
                icon: <Delete />,
                title: <CustomTransComponent translationKey={'COMMON:DELETE'} />,
                onClick: (): void => deleteRef?.current?.handleModalAction?.(data),
            });
        }

        return {
            id: 'profile-menu',
            menuGroups,
            menuTitle: '',
            menuSubtitle: '',
            avatar: (open?: any): JSX.Element => (
                <Avatar className="bg-light-blue-avatar">
                    <MoreVert className={`${open ? theme?.palette?.primary?.main : 'color-content'}`} />
                </Avatar>
            ),
        };
    };

    React.useEffect(() => {
        if (date) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                filters: { ...prev.filters, updatedAt: new Date(date)?.getTime() + 86399000 },
            }));
        }
    }, [date]);

    const getDateHeaderOptions = (): JSX.Element => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar disableFuture value={date ?? null} onChange={(data: any): void => setDate(data)} />
        </LocalizationProvider>
    );

    const getDateSx = (): any => ({
        width: 'auto !important',
        maxHeight: '500px !important',
    });

    const getActive = (data: any): JSX.Element =>
        data['status'] === 'ACTIVE' ? <Check sx={{ color: theme?.palette?.primary?.main }} /> : <></>;

    const handleChipDelete = (key: any): void => {
        tableRef?.current?.resetFilters(key === 'groupId' ? 'groupPath' : key, true);
        const paginationPayloadClone = JSON.parse(JSON.stringify(payload));
        delete paginationPayloadClone?.filters[key];
        setPayload(paginationPayloadClone);
    };

    const headers = [
        {
            header: <CustomTransComponent translationKey={'COMMON:NAME'} />,
            accessor: 'name',
            width: '15%',
            isDebounce: true,
            isFilterable: true,
            isSortable: true,
            cell: getName,
            skeleton: (
                <Stack direction={'row'} alignItems={'center'} gap={1}>
                    <Skeleton animation="wave" variant="circular">
                        <Avatar />
                    </Skeleton>
                    <Skeleton>
                        <Typography>Dashboard Name</Typography>
                    </Skeleton>
                </Stack>
            ),
        },
        {
            header: <CustomTransComponent translationKey={'COMMON:ASSIGNMENT'} />,
            accessor: 'assignment',
            width: '10%',
            isFilterable: true,
            isDebounce: true,
            cell: (data: any): JSX.Element => getAssignment(data, 'assignment'),
            filterOptions: [
                { id: 'all', label: 'All' },
                { id: 'SYSTEM', label: 'System' },
                { id: 'USER', label: 'User' },
            ],
        },
        {
            header: <CustomTransComponent translationKey={'COMMON:LAST_MODIFICATION'} />,
            width: '20%',
            accessor: 'updatedAt',
            isFilterable: true,
            headerOptions: getDateHeaderOptions,
            typeVariant: 'body2',
            cell: getUpdateTime,
            headerSx: getDateSx,
            skeleton: <DateAndYearSkelton />,
        },
        {
            header: <CustomTransComponent translationKey={'COMMON:TYPE'} />,
            width: '10%',
            accessor: 'assignType',
            isFilterable: true,
            typeVariant: 'body2',
            filterOptions: [
                { id: 'DEVICE', label: 'DEVICE' },
                { id: 'GROUP', label: 'GROUP' },
                { id: 'SOURCE', label: 'SOURCE' },
            ],
        },
        {
            header: <CustomTransComponent translationKey={'COMMON:DEFAULT'} />,
            isFilterable: true,
            accessor: 'status',
            width: '10%',
            cell: getActive,
            filterOptions: [
                { id: 'ACTIVE', label: 'ACTIVE' },
                { id: 'INACTIVE', label: 'INACTIVE' },
            ],
        },
        {
            header: <CustomTransComponent translationKey={'COMMON:USED_ON'} />,
            width: '10%',
            accessor: 'associatedCount',
        },
        {
            header: <CustomTransComponent translationKey={'COMMON:DESCRIPTION'} />,
            width: '15%',
            accessor: 'description',
        },
        {
            width: '10%',
            cell: getActionCell,
            skeleton: (
                <Stack direction={'row'} alignItems={'center'} gap={1.5}>
                    <Skeleton animation="wave" variant="circular">
                        <Avatar />
                    </Skeleton>
                    <Skeleton animation="wave" variant="circular">
                        <Avatar />
                    </Skeleton>
                </Stack>
            ),
        },
    ];

    const getModifiedData = (filters: any): any => {
        const modifiedFilters: any = JSON.parse(JSON.stringify(filters));
        if (modifiedFilters?.assignment?.length > 1) {
            modifiedFilters['assignment'] = 'ALL';
        } else if (modifiedFilters?.assignment?.length) {
            modifiedFilters['assignment'] = modifiedFilters?.assignment[0];
        }
        return modifiedFilters;
    };

    const [tableFilters] = useFilteredValues({ allFilters: headers, triggeredFilters: payload?.filters });

    const {
        data: dashboardsData,
        isLoading,
        isFetching,
    }: any = useGetAllDashboardsQuery(
        { body: { ...payload, filters: getModifiedData(tableFilters) } },
        { skip: !currentOrg?.id }
    );

    const getLabel = (key: string): string => {
        switch (key) {
            case 'assignType':
                return 'Type';
            case 'updatedAt':
                return 'Last Modification';
            default:
                return toFirstLetterUpperCase(key);
        }
    };

    return (
        <Stack p={3}>
            <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                <Stack className="border-1 border-radius-4" direction={'row'} marginBottom={2}>
                    <IconButton type="button" className="padding-10 color-content f-16" aria-label="search">
                        <Search />
                    </IconButton>
                    <InputWithCustomPlaceholder
                        id="searchKey"
                        value={searchKey ?? ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                            setSearchKey(e.target.value);
                        }}
                        placeholder={'COMMON:SEARCH_PLACEHOLDER'}
                    />
                </Stack>

                {payload?.filters && (
                    <Stack direction="row" spacing={1}>
                        {Object.keys(payload?.filters).map((key: string) => (
                            <Chip
                                key={`unique${key}`}
                                label={getLabel(key)}
                                onDelete={(): void => {
                                    handleChipDelete(key);
                                }}
                            />
                        ))}
                    </Stack>
                )}
            </Stack>
            <Paper className="margin-top-24">
                <CustomTable
                    ref={tableRef}
                    isPagination
                    handlePageChange={handleDashboardPageChange}
                    isLoading={isLoading || isFetching}
                    data={dashboardsData?.data?.records ?? []}
                    noDataFoundIcon={<Dashboard fontSize="inherit" />}
                    noDataFoundTitle={<CustomTransComponent translationKey={'DASHBOARDS:NO_DASHBOARD'} />}
                    keyToTraverse="id"
                    total={dashboardsData?.data?.total}
                    headers={headers}
                    handleFilterChange={handleFilterChange}
                    containerHeight={280}
                />
            </Paper>
            <DeleteDashboardModal ref={deleteRef} />
            <EditDashboardDetails ref={editRef} />
            <CreateDashboardModal
                actionButtonName={<CustomTransComponent translationKey={'COMMON:CLONE'} />}
                header={<CustomTransComponent translationKey={'DASHBOARDS:CLONE_DASHBOARD'} />}
                ref={cloneRef}
            />
            <AssociateDeviceModal ref={associateDeviceRef} />
        </Stack>
    );
};
