// eslint-disable-next-line @typescript-eslint/naming-convention
import React from 'react';
import { api } from '../app/api/baseApi';
import { useGetWidgetDetails } from './useGetWidgetDetails';
import { customEndpointSelector } from '../utils/helpers';
import { useLazyGetDashboardWidgetByIdQuery as useLazyGetWidgetByIdQuery } from '../features/widgetManagement/widgetApi';
import { useAppDispatch, useTypedSelector } from '../app/store';
import {
    setSummaryResponse,
    setSummaryWidgetDetailsLoader,
    setWidgetList,
} from '../features/dashboardManagement/commonDashboardSlice';

type ReturnProps = {
    isLoading: boolean;
};

export const useCommonWidgetConfiguration = (dashboardData: any, dataGetter: any): ReturnProps => {
    const dispatch = useAppDispatch();

    const widgets = useTypedSelector((rootState) => rootState.commonDashboard.widgetList);
    const loadingState = useTypedSelector((rootState) => rootState.commonDashboard.loaders);
    const apiResponses = useTypedSelector((rootState) => rootState.commonDashboard.apiResponse);

    const [getWidgetConfig, { isFetching, isLoading }] = useLazyGetWidgetByIdQuery();

    const { payload, response: widgetResponse } = useGetWidgetDetails({
        selectorType: 'commonDashboard',
        dataGetter,
    });

    function widgetConfigHandler(widgetId: any): void {
        getWidgetConfig(widgetId)
            .then((res: any) => {
                dispatch(setWidgetList({ data: res?.data?.data, widgetId: widgetId }));
            })
            .catch((err: any) => {
                console.error(err);
            });
    }

    React.useEffect(() => {
        if (dashboardData?.widgetIdList?.length) {
            dashboardData?.widgetIdList?.forEach((widgetId: any) => {
                widgetConfigHandler(widgetId);
            });
        }
    }, [dashboardData]);

    React.useEffect(() => {
        if (widgets?.length === apiResponses?.length) {
            apiResponses.forEach(({ responses, type, widgetId }: any) => {
                if (!loadingState[widgetId]) {
                    (widgetResponse[type] || widgetResponse['default'])?.(responses, widgetId);
                }
            });
        }
    }, [apiResponses, widgets]);

    React.useEffect(() => {
        if (widgets?.length === dashboardData?.widgetIdList?.length) {
            getValues();
        }
    }, [widgets, dashboardData]);

    function setWidgetValue(widget: any, endpoint: any): Promise<any> {
        const selectedWidgetConfig = widgets?.find((prevWidget: any) => prevWidget?.id === widget?.id)?.config;

        const key = `${widget?.widgetType?.id}${
            endpoint?.endpoint === 'getDeviceAlertCount' || endpoint?.endpoint === 'getAvailableCommands'
                ? `-${endpoint?.endpoint}`
                : ''
        }`;

        return new Promise((resolve) => {
            dispatch(
                (api as any)?.endpoints?.[endpoint?.endpoint]?.initiate(
                    (payload?.[key] || payload?.['default'])?.(selectedWidgetConfig),
                    { forceRefetch: true }
                )
            ).then((response: any) => {
                resolve(response);
            });
        });
    }

    function getValues(): void {
        widgets?.forEach((widget: any) => {
            if (widget?.widgetType?.internalCdnJsLink) {
                dispatch(setSummaryWidgetDetailsLoader({ key: widget?.id, value: 'loaded' }));
            }
            if (!loadingState[widget?.id]) {
                const promises: any = [];

                for (let i = 0; i < customEndpointSelector(widget?.widgetType?.id)?.length; i++) {
                    promises.push(setWidgetValue(widget, customEndpointSelector(widget?.widgetType?.id)[i]));
                }
                Promise.all(promises)
                    .then((responses) => {
                        dispatch(setSummaryResponse({ widget, responses }));
                    })
                    .catch((e) => {
                        console.error(e);
                        // Handle errors here
                    });
            }
        });
    }

    return {
        isLoading: isLoading || isFetching,
    };
};
