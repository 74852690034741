import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import { useTheme } from '@mui/material/styles';
import HighchartsReact from 'highcharts-react-official';
import { Box, Button, Checkbox, Divider, MenuItem, Paper, Stack } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useGetDeviceDetails } from 'hooks';
import { useGetDeviceByIdQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { StyledMenu } from './StyledMenu';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import Download from '@mui/icons-material/Download';
import { ExportModal } from 'pages/common/ExportModal';
import { CustomTransComponent } from 'components';
import { useTransformData } from 'hooks/useTransformData';
import { CHART_INITIAL_CONFIG } from 'pages/WidgetManagement/common';
import { useAppDispatch } from '@fiji/common';
import { setAppHeaderSubTitle, setAppHeaderTitle } from '@fiji/common/src/features/common/commonSlice';

export const unitList = [
    { value: 'A', name: 'Amps' },
    { value: '°C', name: 'Celsius' },
    { value: 'kW', name: 'Kilowatts' },
    { value: '%', name: 'Percentage' },
    { value: 'dV', name: 'Voltage' },
];
const Chart = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const theme: any = useTheme();
    const { deviceId }: any = useParams();
    const { state } = useLocation();
    const modalRef: any = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedUnits, setSelectedUnits] = React.useState<any>([]);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };
    const { data: deviceData }: any = useGetDeviceByIdQuery(deviceId, { skip: !deviceId });
    const { pointConversion } = useTransformData();

    const { data, payload } = useGetDeviceDetails({
        sourceId: deviceData?.data?.deviceTypeId,
        deviceId: deviceId,
        widgetType: 'details',
    });
    const chartRef: any = useRef(null);
    const [option, setOption] = React.useState(CHART_INITIAL_CONFIG);

    const getSeriesData = (): any[] => {
        let channelData: any = data?.secondary?.[0]?.channels?.filter((item: any) => item?.dataType === 'Number');
        if (selectedUnits?.length && selectedUnits?.length !== unitList?.length) {
            channelData = channelData?.filter((item: any) => selectedUnits?.includes(item?.unit));
        }

        return channelData
            ?.filter((item: any) => item?.value)
            ?.map((item: any, index: number) => ({
                pointPadding: 0,
                groupPadding: 0,
                borderWidth: 0,
                id: index,
                name: item?.displayName ?? '',
                data: [+item?.value ?? 0],
                tooltip: {
                    valueSuffix: item?.unit ? ` ${item?.unit}` : '',
                },
                stack: index,
            }));
    };
    const getIntervalValue = (dataValue: any): any => {
        const value: number = dataValue?.reduce?.(
            (max: any, current: any) => (current.value > max.value ? current : max),
            dataValue?.[0]
        )?.value;
        if (value > 10) return Math.ceil(value / 10);
        return value ? (value / 12)?.toFixed?.(2) : undefined;
    };

    useEffect(() => {
        if (state?.widgetName) {
            dispatch(setAppHeaderTitle(state?.widgetName));
        }
        return (): any => {
            dispatch(setAppHeaderTitle(''));
        };
    }, [state?.widgetName]);

    useEffect(() => {
        if (deviceData) {
            dispatch(setAppHeaderSubTitle(deviceData?.data?.name));
        }
        return (): any => {
            dispatch(setAppHeaderSubTitle(''));
        };
    }, [deviceData]);

    useEffect(() => {
        const chart = chartRef?.current?.chart;
        if (chart && data?.secondary) {
            const cloneChart: any = JSON.parse(JSON.stringify(option));
            cloneChart['series'] = getSeriesData() ?? [];
            cloneChart['chart'] = {
                ...cloneChart['chart'],
                height: 600,
            };
            const newData: any = JSON?.parse(JSON.stringify(data?.secondary?.[0]?.channels ?? []));
            cloneChart['yAxis'] = {
                ...(option?.yAxis ?? {}),
                labels: {
                    format: `{value} ${
                        selectedUnits
                            ? newData
                                  ?.filter((item: any) => selectedUnits?.includes(item?.unit))
                                  .reduce?.(
                                      (max: any, current: any) => (current?.value > max?.value ? current : max),
                                      data[0]
                                  )?.unit ?? ''
                            : newData?.reduce?.(
                                  (max: any, current: any) => (current?.value > max?.value ? current : max),
                                  data[0]
                              )?.unit ?? ''
                    }`,
                    overflow: 'justify',
                },
                tickInterval: getIntervalValue(newData),
            };
            cloneChart['plotOptions'] = {
                bar: {
                    borderRadius: '20%',
                    pointWidth: 30,
                    dataLabels: {
                        enabled: true,
                        y: 25,
                        alignTo: 'plotEdges',
                        align: 'left',
                    },
                },
                column: {
                    borderRadius: '20%',
                    pointWidth: 30,
                },
                series: {
                    groupPadding: 0,
                    pointPadding: 0.5,
                    dataLabels: {
                        enabled: true,
                        // align: 'left',
                        formatter: function (this: any): string {
                            return `${pointConversion(this?.y)} ${this?.series?.tooltipOptions?.valueSuffix} ${
                                this?.series?.name
                            }`;
                        },
                        style: {
                            fontSize: '14px',
                        },
                    },
                },
            };
            setOption(cloneChart);
        }
    }, [data?.secondary, selectedUnits]);

    const changeHandler = (e: any, value: string): void => {
        if (selectedUnits?.includes(value)) {
            setSelectedUnits(selectedUnits?.filter((item: string) => item !== value));
        } else setSelectedUnits((prev: any) => [...prev, value]);
    };

    return (
        <Paper>
            <Stack display="flex" flexDirection="column">
                <Stack
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    alignItems={'flex-end'}
                    p={2}
                    pr={5}
                >
                    <Box mr={2}>
                        <Button
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            sx={{
                                backgroundColor: 'transparent',
                                color: '#424E54',
                            }}
                            onClick={handleClick}
                            endIcon={<ArrowDropUp />}
                        >
                            <CustomTransComponent translationKey={'COMMON:UNITS'} />
                        </Button>
                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem className="w-100" key="all">
                                <Checkbox
                                    className="margin-right-16"
                                    indeterminate={
                                        Boolean(selectedUnits.length) && selectedUnits.length < unitList?.length
                                    }
                                    checked={selectedUnits.length === unitList?.length}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                        if (!e?.target?.checked) {
                                            setSelectedUnits([]);
                                        } else {
                                            setSelectedUnits(unitList?.map((item: any) => item.value));
                                        }
                                    }}
                                />
                                <CustomTransComponent translationKey={'COMMON:SELECT_ALL'} />
                            </MenuItem>
                            <Divider />
                            {unitList?.map((item: any): any => (
                                <MenuItem key={item?.value} value={item?.value} className="w-100">
                                    <Checkbox
                                        className="margin-right-16"
                                        checked={selectedUnits?.includes(item.value)}
                                        onChange={(e): void => changeHandler(e, item.value)}
                                    />
                                    {`(${item?.value}) ${item.name}`}
                                </MenuItem>
                            ))}
                        </StyledMenu>
                    </Box>
                    <Button
                        sx={{
                            border: `1px solid ${theme?.palette?.primary?.main}`,
                            '&:hover': {
                                backgroundColor: theme?.palette?.primary?.[50],
                            },
                        }}
                        variant="outlined"
                        startIcon={<Download />}
                        onClick={() => modalRef?.current?.onClick({ payload: payload })}
                    >
                        <CustomTransComponent translationKey={'COMMON:EXPORT'} />
                    </Button>
                </Stack>
                <Stack sx={{ height: 'calc(100vh - 200px)' }}>
                    <HighchartsReact highcharts={Highcharts} options={option} ref={chartRef} />
                </Stack>
            </Stack>
            <ExportModal ref={modalRef} header="Export Charts" />
        </Paper>
    );
};
export { Chart };
